.ck-tracking-image {
    overflow: hidden;
    position: relative;
    display: inline-block;
    height: 400px;
    width: 33%;

    .img-container {
        height: 100%;
        transition: transform .3s ease;

        &:hover {
            transform: scale(1.1);
            /*
            Change the scale value as much as you want to change the amount of zoom on hover.
            */
        }

        img {
            height: 100%;
            opacity: .5;
            transform: translate(0,0);
            /*
            Change the opacity value to change how "faded" you want the image to appear when it is not hovered.
            */
            transition: opacity .25s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }
    }
}
