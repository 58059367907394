// test page: https://codepen.io/melnik909/pen/BQBLoO
/*
* core styles
*/

.sm-link{
	--uismLinkDisplay: var(--smLinkDisplay, inline-flex);
	--uismLinkTextColor: var(--smLinkTextColor);
	--uismLinkTextColorHover: var(--smLinkTextColorHover);

	display: var(--uismLinkDisplay);
	color: var(--uismLinkTextColor);
	position: relative;
	overflow: hidden;
}

a.sm-link{
	text-decoration: none;
}

.sm-link__label{
  display: block;
}

/* sm-link_padding-all */

.sm-link_padding-all{
	--uismLinkLineWeight: var(--smLinkLineWeight, 2px);
	--uismLinkLineColor: var(--smLinkLineColor, #fff);
	--uismLinkPadding: var(--smLinkPadding, 5px);

	padding: var(--uismLinkPadding);
}

.sm-link_padding-all::before,
.sm-link_padding-all::after{
  width: 100%;
  height: var(--uismLinkLineWeight);
  left: 0;
}

.sm-link_padding-all::before{
  top: 0;
}

.sm-link_padding-all::after{
  bottom: 0;
}

.sm-link_padding-all .sm-link__label::before,
.sm-link_padding-all .sm-link__label::after{
  width: var(--uismLinkLineWeight);
  height: 100%;
  top: 0;
}

.sm-link_padding-all .sm-link__label::before{
  left: 0;
}

.sm-link_padding-all .sm-link__label::after{
  right: 0;
}

.sm-link_padding-all::before,
.sm-link_padding-all::after,
.sm-link_padding-all .sm-link__label::before,
.sm-link_padding-all .sm-link__label::after{
  content: "";
	background-color: var(--uismLinkLineColor);
  position: absolute;
	opacity: 0;

	will-change: transform, opacity;
	transition-property: transform, opacity;
}

.sm-link_padding-all:hover::before,
.sm-link_padding-all:hover::after,
.sm-link_padding-all:hover .sm-link__label::before,
.sm-link_padding-all:hover .sm-link__label::after{
	opacity: 1;
}

/* sm-link_padding-bottom */

.sm-link_padding-bottom{
	--uismLinkLineWeight: var(--smLinkLineWeight, 2px);
	--uismLinkLineColor: var(--smLinkLineColor, #000);

	padding-bottom: var(--uismLinkLineWeight);
	position: relative;
}

.sm-link_padding-bottom::after{
  content: "";
  width: 100%;
  height: var(--uismLinkLineWeight);
	background-color: var(--uismLinkLineColor);

  position: absolute;
  left: 0;
  bottom: 0;
}

/* sm-link_bg */

.sm-link_bg {
	--uismLinkLineColor: var(--smLinkLineColor, #000);
	--uismLinkTextColorHover: var(--smLinkTextColorHover, #fff);
	--uismLinkPadding: var(--smLinkPadding, 5px);

	padding: var(--uismLinkPadding);
	transition: color .3s ease-out;
}

.sm-link_bg::before,
.sm-link_bg::after{
  content: "";
	background-color: var(--uismLinkLineColor);
  opacity: 0;
  position: absolute;

	transition: transform .2s ease-out, opacity .2s ease-out .03s;
}

.sm-link_bg .sm-link__label{
  position: relative;
  z-index: 2;
}

.sm-link_bg:hover::before,
.sm-link_bg:hover::after{
  opacity: 1;
	transition-duration: .35s, .35s;
	transition-delay: 0s, 0s;
}

.sm-link_bg:hover{
	color: var(--uismLinkTextColorHover);
}

/* sm-link_text */

.sm-link_text::before{
  content: attr(data-sm-link-text);
	color: var(--uismLinkTextColorHover);
  position: absolute;
}

.sm-link_text::before,
.sm-link_text .sm-link__label{
  transition-property: transform;
	transition-timing-function: cubic-bezier(.86, .6, .08, 1.01);
	transition-duration: .3s;
}

.sm-link_text:hover::before,
.sm-link_text:hover .sm-link__label{
	transition-duration: .4s;
}

/* effect 1 */

.sm-link1::before{
  transform: translate3d(-105%, 0, 0);
}

.sm-link1::after{
  transform: translate3d(105%, 0, 0);
}

.sm-link1 .sm-link__label::before{
  transform: translate3d(0%, -100%, 0);
}

.sm-link1 .sm-link__label::after{
  transform: translate3d(0%, 100%, 0);
}

.sm-link1::before,
.sm-link1::after,
.sm-link1 .sm-link__label::before,
.sm-link1 .sm-link__label::after{
	transition-timing-function: ease-out;
	transition-duration: .2s, .15s;
	transition-delay: 0s, .15s;
}

.sm-link1:hover::before,
.sm-link1:hover::after,
.sm-link1:hover .sm-link__label::before,
.sm-link1:hover .sm-link__label::after{
  transform: translate3d(0, 0, 0);
	opacity: 1;

	transition-duration: .25s;
	transition-delay: 0s;
}

/* effect 2 */

.sm-link2::before{
	top: 0;
  transform: rotateY(90deg);
	transition-duration: .3s;
}

.sm-link2 .sm-link__label{
  transform: rotateY(0);
	transition-delay: .3s;
	transition-duration: .3s;
}

.sm-link2:hover::before{
  transform: rotateY(0deg);
	transition-delay: .3s;
}

.sm-link2:hover .sm-link__label{
  transform: rotateY(90deg);
	transition-delay: 0s;
	transition-duration: .3s;
}

/* effect 3 */

.sm-link3::after{
  transform: translate3d(-100%, 0, 0);
  transition: transform .2s ease-in;
}

.sm-link3:hover::after{
  transform: translate3d(0, 0, 0);
}

/* effect 4 */

.sm-link4::after{
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition: transform .3s ease-out, opacity .3s ease-out;
}

.sm-link4:hover::after{
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* effect 5 */

.sm-link5::before,
.sm-link5::after,
.sm-link5 .sm-link__label::before,
.sm-link5 .sm-link__label::after{
	transition-timing-function: ease-out;
	transition-duration: .2s, .15s;
	transition-delay: 0s, .15s;
}

.sm-link5::before{
  transform: translate3d(-100%, 0, 0);
}

.sm-link5::after{
  transform: translate3d(100%, 0, 0);
}

.sm-link5 .sm-link__label::before{
  transform: translate3d(0, 100%, 0);
}

.sm-link5 .sm-link__label::after{
  transform: translate3d(0, -100%, 0);
}

.sm-link5:hover::before,
.sm-link5:hover::after,
.sm-link5:hover .sm-link__label::before,
.sm-link5:hover .sm-link__label::after{
  transform: translate3d(0, 0, 0);
	transition-delay: 0s;
}

/* effect 6 */

.sm-link6::before,
.sm-link6::after,
.sm-link6 .sm-link__label::before,
.sm-link6 .sm-link__label::after{
  transition-duration: .2s;
  transition-timing-function: ease-out;
}

.sm-link6::before,
.sm-link6::after{
  width: 100%;
  height: var(--uismLinkLineWeight);
  left: 0;
}

.sm-link6 .sm-link__label::before,
.sm-link6 .sm-link__label::after{
  width: var(--uismLinkLineWeight);
  height: 100%;
  top: 0;
}

.sm-link6::before{
  top: 0;
  transform: translate3d(-105%, 0, 0);
}

.sm-link6::after{
  bottom: 0;
  transform: translate3d(105%, 0, 0);
}

.sm-link6 .sm-link__label::before{
  left: 0;
  transform: translate3d(0, 105%, 0);
}

.sm-link6 .sm-link__label::after{
  right: 0;
  transform: translate3d(0, -105%, 0);
}

.sm-link6:hover::before,
.sm-link6:hover::after,
.sm-link6:hover .sm-link__label::before,
.sm-link6:hover .sm-link__label::after{
  transform: translate3d(0, 0, 0);
}

.sm-link6:hover::before{
  transition-delay: 0s;
}

.sm-link6 .sm-link__label::after,
.sm-link6:hover::after{
  transition-delay: .25s;
}

.sm-link6::after,
.sm-link6:hover .sm-link__label::after{
  transition-delay: .15s;
}

.sm-link6::before,
.sm-link6:hover .sm-link__label::before{
  transition-delay: .35s;
}

/* effect 7 */

.sm-link7::before{
  left: 0;
  top: 0;
  transform: translate3d(-110%, 0, 0);
}

.sm-link7 .sm-link__label,
.sm-link7:hover::before{
  transform: translate3d(0, 0, 0);
}

.sm-link7:hover .sm-link__label{
  transform: translate3d(110%, 0, 0);
}

/* effect 8 */

.sm-link8::before{
  left: 0;
  top: 0;
  transform: translate3d(0, 140%, 0);
}

.sm-link8 .sm-link__label,
.sm-link8:hover::before{
	transform: translate3d(0, 0, 0);
}

.sm-link8:hover .sm-link__label{
  transform: translate3d(0, -140%, 0);
}

/* effect 9 */

.sm-link9::before,
.sm-link9::after{
  width: 50%;
  height: 100%;
  top: 0;
}

.sm-link9::before{
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.sm-link9::after{
  right: 0;
  transform: translate3d(100%, 0, 0);
}

.sm-link9:hover::before,
.sm-link9:hover::after{
  transform: translate3d(0, 0, 0);
}

/* effect 10 */

.sm-link10::before,
.sm-link10::after{
  width: 100%;
  height: 50%;
  left: 0;
}

.sm-link10::before{
  top: 0;
  transform: translate3d(0, -50%, 0);
}

.sm-link10::after{
  bottom: 0;
  transform: translate3d(0, 50%, 0);
}

.sm-link10:hover::before,
.sm-link10:hover::after{
  transform: translate3d(0, 0, 0);
}

/* effect 11 */

.sm-link11::before,
.sm-link11::after{
  width: 51%;
  height: 100%;
  transform: rotate(360deg);
  top: 0;
}

.sm-link11::before{
  left: 0;
}

.sm-link11::after{
  right: 0;
}

.sm-link11:hover::before,
.sm-link11:hover::after{
  transform: rotate(0);
}

/* effect 12 */

.sm-link12::before,
.sm-link12::after{
  width: 51%;
  height: 100%;
  top: 0;
}

.sm-link12::before{
  left: 0;
  transform: translate3d(-100%, 0, 0) rotate(-45deg);
}

.sm-link12::after{
  right: 0;
  transform: translate3d(100%, 0, 0) rotate(-45deg);
}

.sm-link12:hover::before,
.sm-link12:hover::after{
  transform: translate3d(0, 0, 0);
}

/*
SETTINGS
*/

.sm-link{
	--smLinkPadding: 10px 15px;
	--smLinkLineWeight: 5px;
	//--smLinkLineColor: #ff9000;
	//--smLinkTextColor: #ff9000;
	//--smLinkTextColorHover: #ff9000;
}

.sm-link_bg{
	//--smLinkTextColorHover: #fff;
}
