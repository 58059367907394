.ck-svg-revealing {
    background: url('../assets/images/main-image.jpg');
    background-size: cover;
    width: 40vmin;
    height:auto;
    display: block;

    text {
        font-size: 10px;
        transition: font-size .4s ease-out;
        font-weight: 900;
        font-family: arial;
    }

    &:hover text {
        transition: font-size .4s ease-in;
        font-size: 300px;
    }

    rect {
        fill: $backgroundColor;
        fill-opacity: 0.8;
    }
}
