$t: 2.5s;
$e1: cubic-bezier(0.8, 0.00, 0.1, 1);
$e2: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$er: linear;

$color: $firstColor;
$font: 'Abril Fatface', sans-serif;

$slides: 3;
$images: url(../assets/images/main-image-1.jpg) url(../assets/images/main-image-2.jpg) url(../assets/images/main-image-3.jpg);


.ck-css-slideshow {
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;
	@keyframes rotateHue {
		0%{ filter: hue-rotate(0deg); }
		20%{ filter: hue-rotate(0deg); }
		25%{ filter: hue-rotate(90deg); }
		45%{ filter: hue-rotate(90deg); }
		50%{ filter: hue-rotate(180deg); }
		70%{ filter: hue-rotate(180deg); }
		75%{ filter: hue-rotate(270deg); }
		95%{ filter: hue-rotate(270deg); }
		100%{ filter: hue-rotate(360deg); }
	}
	//animation: rotateHue infinite $t*$slides*2 linear;
	animation-delay: $t/4;

	.shutters{
		position: absolute;
		height: 150vmax;
		width: 150vmax;
		left: calc(50% - 75vmax);
		top: calc(50% - 75vmax);
		pointer-events: none;
		z-index: 2;
		@keyframes rotateFrame{
			0%{ transform: rotate(0deg);}
			100%{ transform: rotate(180deg);}
		}
		animation: rotateFrame $t*4 $er infinite;

		&::before, &::after{
			content: '';
			position: absolute;
			height: 100%;
			width: 100%;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			background-color: $color;
			pointer-events: auto;
		}

		&::before{
			bottom: 50%;
			@keyframes openTop{
				0%{ transform: translate3d(-50%, 0, 0); animation-timing-function: $e1; }
				40%{ transform: translate3d(-50%, -65vmax, 0); animation-timing-functon: $e2; }
				70%{ transform: translate3d(-50%, -65vmax, 0); animation-timing-functon: $e2; }
				100%{ transform: translate3d(-50%, 0, 0); animation-timing-function: $e2; }
			}
			animation: openTop $t*2 infinite;
		}

		&::after{
			top: 50%;
			@keyframes openBottom{
				0%{ transform: translate3d(-50%, 0, 0); animation-timing-function: $e1; }
				40%{ transform: translate3d(-50%, 65vmax, 0); animation-timing-functon: $e2; }
				70%{ transform: translate3d(-50%, 65vmax, 0); animation-timing-functon: $e2; }
				100%{ transform: translate3d(-50%, 0, 0); animation-timing-function: $e2; }
			}
			animation: openBottom $t*2 infinite;
		}
	}

	.slides{
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-color: $color;
	}

	.slide{
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0;
		@keyframes showHideSlide{
			0%{
				opacity: 1;
				pointer-events: auto;
				z-index: 1;
			}
			#{100% / $slides}{
				opacity: 0;
				pointer-events: none;
				z-index: -1;
			}
			100%{
				opacity: 0;
				pointer-events: none;
				z-index: -1;
			}
		}
		animation: showHideSlide infinite $t*$slides*2 steps(1);

		@for $i from 1 through $slides{
			&:nth-child(#{$i}){
				animation-delay: $t * ($i - 1) * 2;

				.slideBg{
					background-image: nth($images, $i);
				}
			}
		}
	}

	.slideBg{
		position: relative;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center;
		opacity: 1;
		z-index: -1;
		@keyframes bgInOut{
			0%{ transform: rotate(-45deg) scale(1.1); animation-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);}
			33%{ transform: rotate(0deg);}
			50%{ transform: rotate(0deg); }
			66%{ transform: rotate(0deg); animation-timing-function: cubic-bezier(0.895, 0.030, 0.685, 0.220); }
			100%{ transform: rotate(45deg) scale(0.9);}
		}
		animation: bgInOut $t*4 -$t*2 infinite;
	}

	.slideContent{
		position: absolute;
		top: 64px;
		left: 30px;
		border-radius: 2px;
		color: $firstColorText;
		background-color: $firstColor;
		padding: 25px;
		font-family: $font;
		line-height: 0.8;
		letter-spacing: -0.025em;
		z-index: 2;
		opacity: 0.9;
		span{
			display: block;
			font-size: 15vmin;
		}
	}

	.button{
		font-family:'Roboto Mono', sans-serif;
		text-decoration: none;
		font-weight: 800;
		text-transform: uppercase;
		font-size: 2vmin;
		display: inline-block;
		position: relative;
		border: 2px solid $firstColorText;
		background: transparent;
		margin-top: 5vmin;
		color: $firstColorText;
		padding: 2vmin 2vmin 1.8vmin 2vmin;
		letter-spacing: 0.1em;
		text-shadow: none;
		line-height: 1;
		transition: all 100ms linear;
		border-radius: 2px;

		&:hover{
			//transform: translate3d(1vmin, -1vmin, 0);
			box-shadow: 1px 1px 10px rgba($firstColorText, 0.5);
			background: $firstColorText;
			color: black;
		}

		&:active{
			transform: translate3d(0px, 0px, 0);
			box-shadow: -0px 0px 0 rgba($firstColorText, 0.5);
		}
	}
}
