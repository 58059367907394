@import '../../../node_modules/materialize-css/sass/materialize';
@import './modules/material-icons';
@import './modules/colors';
@import './modules/responsive';
@import './modules/typography';

// link hover effects
@import './modules/link-hover-effects';

// image effects
@import './modules/image-effects/image-border';
@import './modules/image-effects/tracking-image-hover';
@import './modules/image-effects/svg-revealing-image';
@import './modules/image-effects/split-image-hover';
@import './modules/image-effects/square-fracture-hover';
@import './modules/image-effects/image-hover-shift';
@import './modules/image-effects/css-slideshow';

// text effects
@import './modules/text-effects/appearing-text';


nav.mainMenu {
    position: fixed;
    z-index: 99;
    display: flex;
    flex-direction: row;

    .sidenav-trigger {
        display: block;
    }

    .navbarLogoWrapper {
        height: 100%;

        .navbarLogo {
            //padding: 0 12px;
            max-height: 100%;
        }

        .navbarCaiLogo {
            padding: 0 12px;
        }
    }

    .companyName {
        font-size: 1.3rem;
        text-transform: uppercase;
        margin-left: 35px;
        flex-grow: 1;
    }
}

.sidenav {
    background-image: url('../assets/images/sidenav-background.png');
    background-repeat: no-repeat;
    background-position: bottom left;


    .sidenavLogoWrapper {
        height: 128px;
        text-align: center;
        border-bottom: solid 3px;

        .sidenavLogo {
            max-height: 100%;
        }
    }
}

html {
    overflow-x: hidden;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    overflow-x: hidden;
}

main {
    flex: 1 0 auto;
    margin-top: 60px;
    position: relative;

    #mainBackground {
        background-image: url('../assets/images/background.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: calc(100vw - 70%);
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        opacity: 0.3;
    }

    img {
        max-width: 100%;
    }

    .imageDescription {
        font-size: 15px;
    }

    .contentImage {
        margin: {
            top: 20px;
            bottom: 20px;
        }
        width: 500px;
        border: solid 2px $borderColor;
    }

    .contentLogo {
        margin: {
            top: 20px;
            bottom: 20px;
        }
        max-width: 200px;
    }

    .contentHeader {
        width: 100%;
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        height: 500px;
        transform: perspective(50px) rotateX(-0.5deg);

        &.climbing {
            background-image: url('../assets/images/main-image-1.jpg');
        }

        &.canali-innevati {
            background-image: url('../assets/images/main-canali-innevati.jpg');
        }

        &.trekking {
            background-image: url('../assets/images/main-image-3.jpg');
        }

        &.contatti {
            background-image: url('../assets/images/main-contatti.jpg');
        }

        &.home {
            background-image: url('../assets/images/main-arrampicata.jpg');
        }

        &.prezzi {
            background-image: url('../assets/images/prezzi.jpg');
        }
    }

    .mainContainer {
        background-color: $mainBackgroundColor;
        height: 100%;
        padding: 25px 55px;
        text-align: justify;
    }

    code {
        background-color: $secondColor;
        color: $secondColorText;
        padding: 5px 9px;
    }

    .ck-subtitle {
        text-align: center;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .ck-image-appearing-text {
        position: relative;
        top: 35px;
        //text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;

        .ck-appearing-text-split {
            padding: 11px 0;
            margin-left: 40%;
        }
    }
}

footer {
    a:not(.sm-link) {
        padding: 3px 8px;
    }

    .footerInfo {
        a:link {
            padding: 0;
            color: lighten($thirdColor, 30%);
        }
    }

    .footerHeader {
        padding: 15px 35px;

        .footerMenuContainer {
            a {
                margin-right: 15px;
            }
        }
    }
}

.flexRow {
    display: flex;
    flex-direction: row;

    .flexRowItem {

    }
}
