.ck-square-fracture-hover {
    display: inline-block;
    position: relative;
    width: 1024px;
    height: 250px;
    overflow: hidden;
    box-sizing: border-box;

    .tile {
        position: relative;
        vertical-align: top;
        display: inline-block;
        //border-right: 1px solid rgba(0, 0, 0, 0.5);
        //border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        box-sizing: border-box;

        &::after {
            content: "";
            width: 6px;
            height: 6px;
            position: absolute;
            top: 100%;
            right: 0px;
            transform: translate(50%, -50%);
            z-index: 2;
            line-height: 1;
        }
    }

    // no grid
    .noGrid .tile {
        border-right: 0px solid rgba(0, 0, 0, 0.5);
        border-bottom: 0px solid rgba(0, 0, 0, 0.5);
    }

    .noGrid .tile::after {
        content: none;
    }
}
