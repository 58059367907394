$ck-large-screen: 1200px !default;
$ck-small-screen: 600px !default;
$ck-range : $ck-large-screen - $ck-small-screen !default;
$ck-intervals: 20 !default;
$ck-interval-size: $ck-range / $ck-intervals !default;


.ck-flow-text{
    $i: 0;
    @while $i <= $ck-intervals {
        @media only screen and (min-width : 360 + ($i * $ck-interval-size)) {
            font-size: 1rem * (1 + (.02 * $i));
        }
        $i: $i + 1;
    }

    // Handle below 360px screen
    @media only screen and (max-width: 360px) {
        font-size: 1.2rem;
    }
}

h3 {
    text-transform: uppercase;
    font-size: 2.2rem;
}

h4 {
    font-size: 1.8rem;
}
