.ck-split-image-hover {
    display: inline-block;
    position: relative;
    width: 600px;
    height: 600px;
    overflow: hidden;
    box-sizing: border-box;

    .gridContainer {
        width: 100%;
        height: 100%;
    }

    .gridTile {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-origin: center;
    }
}
