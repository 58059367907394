@media #{$small-and-down} {
    nav.mainMenu {
        .companyName {
            font-size: 1rem !important;
        }
    }

    .slideContent{
		left: 10px !important;
	}

    main {
        margin-top: 40px !important;

        #mainBackground {
            background-size: 50% !important;
        }

        .contentHeader {
            height: 200px !important;
            transform: none !important;
            margin-bottom: 15px;
        }

        .mainContainer {
            padding: 25px 12px !important;
            width: 100%;
        }

        .ck-image-appearing-text {
            .ck-appearing-text-split {
                margin-left: 5% !important;
            }
        }
    }
}
