$backgroundColor: color('grey', 'darken-3') !default;
$mainBackgroundColor: color('grey', 'lighten-5') !default;
$textColor: color('grey', 'darken-4');
$borderColor: color('grey', 'darken-2');

$firstColor: color('grey', 'darken-4') !default;
$firstColorText: color('grey', 'lighten-5') !default;

$secondColor: color('red', 'darken-4') !default;
$secondColorText: color('grey', 'lighten-5') !default;

$thirdColor: color('deep-purple', 'accent-4') !default;
$thirdColorText: color('grey', 'darken-4') !default;


body {
    background: linear-gradient($backgroundColor, darken($backgroundColor, 20%));
    color: $textColor;

    &::-webkit-scrollbar {
        width: 17px;
    }
    &::-webkit-scrollbar-button {
        display: none !important;
    }
    &::-webkit-scrollbar-track {
        background-color: darken($backgroundColor, 60%);
    }
    &::-webkit-scrollbar-thumb {
        background-color: darken($backgroundColor, 30%);
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: darken($backgroundColor, 20%);
    }
    &::-webkit-scrollbar-corner {
        background-color: darken($backgroundColor, 30%);
    }

    h3 {
        color: $secondColor;
    }

    h4, h5 {
        color: $thirdColor;
    }

    h6 {
        color: $secondColor;
    }

    blockquote {
        border-color: $thirdColor;
    }

    a {
        &:link {
            color: $firstColor;
            font-weight: bold;
        }

        &:visited {
            color: darken($firstColor, 5%);
        }

        &:hover, &:active {
            color: lighten($firstColor, 10%);
        }
    }

    .ck-subtitle {
        color: $thirdColor;
    }

    .ck-image-appearing-text {
        background-color: rgba($firstColor, 0.9);
        color: $firstColorText;
        box-shadow: 0 3px 2px rgba($firstColor, 0.5);
    }

    .highlightText {
        color: $secondColor;
    }

    .highlightText-2 {
        color: $thirdColor;
    }

    nav {
        background-color: $firstColor;

        .sidenav-trigger {
            color: $firstColorText;
        }

        .navbarLogo {
            background-color: $backgroundColor;

            &:hover {
                background-color: darken($backgroundColor, 15%);
            }
        }
    }

    .sidenav {
        background-color: $mainBackgroundColor;
        color: $textColor;

        .sidenavLogoWrapper {
            border-color: $firstColor;
        }

        .material-icons {
            color: darken($firstColor, 15%) !important;
        }

        li {
            background-color: $mainBackgroundColor;
            
            &:hover {
                background-color: darken($firstColor, 15%);
                transition: all 0.5s;

                a {
                    color: $secondColorText;

                    .material-icons {
                        color: $secondColorText !important;
                    }
                }
            }
        }
    }

    .sidenav-overlay {
        background-color: rgba(0, 0, 0, 0.75);
    }

    footer.page-footer {
        background-color: lighten($firstColor, 15%);

        .sm-link{
            color: $firstColorText;

        	--smLinkPadding: 10px 15px;
        	--smLinkLineWeight: 5px;
        	--smLinkLineColor: #{$firstColorText};
        	--smLinkTextColor: #{$firstColorText};
        	--smLinkTextColorHover: #{$firstColorText};
        }

        .footerLinks {
            a:hover {
                background-color: $secondColor;
                text-decoration: none;
            }
        }

        .footerHeader {
            background-color: $firstColor;
        }

        .footer-copyright {
            background-color: $firstColor;
        }
    }
}
