.ck-image-border {
	width: 300px;
	height: 300px;
	background-color: $backgroundColor;
	background-image: url('../assets/images/main-image.jpg');
	background-size: cover;
	background-position: center center;
	position: relative;
	z-index: 1;
	margin-bottom: 65px;

	&:after {
		content: '';
		position: absolute;
		width: 290px;
		height:290px;
		left: 5px;
		top: 5px;
		background-color: #fff;
	}

	.main-image {
		position: absolute;
		width: 290px;
		height:290px;
		left: 5px;
		top: 5px;
		background-image: url('../assets/images/main-image.jpg');
		background-size: cover;
		background-position: center center;
		z-index: 2;
		transition: .2s ease-in-out;
	}

	&:hover > .main-image {
		transform: translate(-15px, -15px);
		transition: .2s ease-in-out;
	}

	&:hover > p.title::after {
		transform: scale3d(1, 1, 1);
		transition: .2s ease-in-out;
	}

	p.title {
		height: 60px;
		padding: 0;
		position: absolute;
		bottom: -80px;
		font-weight: 400;
		line-height: 60px;
		color: $textColor;
		font-size: 1em;
		margin-left: 150px;
		transform: translateX(-50%);

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: $firstColor;
			bottom: 10px;
			left: 0;
			transform: scale3d(0, 1, 1);
			transition: .2s ease-in-out;
		}
	}
}
