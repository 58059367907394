.ck-image-hover-shift {
    display: flex;

    .ck-hover-shift-image {
        position: relative;
        overflow: hidden;
        margin: 10px;
        min-width: 255px;
        max-width: 315px;
        height: 220px;
        width: 100%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }

    .ck-hover-shift-image img {
        height: 100%;
        position: absolute;
        right: 0;
        -webkit-transition: all 2s ease-out;
        transition: all 2s ease-out;
    }

    .ck-hover-shift-image:hover img,
    .ck-hover-shift-image.hover img {
        -webkit-transform: translateX(130px);
        transform: translateX(130px);
    }
}
